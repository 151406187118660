import * as React from 'react';
import { graphql } from 'gatsby';
import { useEntities } from '@core/hooks';
import { LABEL, CART } from '@core/constants';
import { pluralize } from '@core/utils';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import EntitiesList from '@components/EntitiesList';
import ProductCategoryFilter from '@components/store/ProductCategoryFilter';
import ProductGrid from '@components/store/ProductGrid';
import ProductItem from '@components/store/ProductItem';
import { PostgresData, Bundle } from '@interface/gatsby';
import { PageProps } from '@interface/common';


type DataType = PostgresData<Record<'bundles', Bundle[]>>;

export default function BundlesIndexPage({ data, categories }: PageProps<DataType>) {
  const bundles = useEntities<Bundle>(data.postgres.bundles);

  return (
    <Main>
      <ProductCategoryFilter categories={categories!}/>
      <ProductGrid>
        <EntitiesList<Bundle>
          entities={bundles}
          render={({ item }) => {
            let productsCount = item.products.totalCount;
            return (
              <ProductItem
                {...item}
                type={CART.ITEM.BUNDLES}
                description={`${productsCount} ${pluralize('product', productsCount)}`}
              />
            )
          }}
        />
      </ProductGrid>
    </Main>
  );
}

export function Head() {
  return <BaseHead title={LABEL.BUNDLES}/>;
}

export const allProductsQuery = graphql`
    query Bundles {
        postgres {
            bundles: bundlesList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                discount
                cover {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED,
                            breakpoints: [750],
                            placeholder: BLURRED,
                            width: 750,
                            quality: 75
                        )
                    }
                }
                products: bundlesProducts {
                    totalCount
                }
            }
        }
    }
`;
